.banner {
    position: relative;
    width: 100%;
    max-width: 1400px;
    height: 500px;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
}

.slider {
    position: relative;
    width: 100%;
    height: 100%;
}

.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    display: block;
    transition: opacity 1s ease-in-out;
}

.caption {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: left;
    padding: 15px;
    font-size: 16px;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.5);
}

/* Arrows (prev and next) */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

 

.widget-wrapper {
    background-color:white; /* Darker semi-transparent background */
     border-radius: 8px; /* Rounded corners */
    text-align: center; /* Center text inside */
    color: white; /* Text color for surrounding elements */
    font-size: 18px; /* Increased font size */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional shadow for better visibility */
    border: 2px solid #ffffff; /* Add a white border for contrast */
}

.caption {
    position: absolute;
    bottom: 20px;  
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;  
    padding: 15px;
    font-size: 20px;  
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.5);
}
/*   */


/* Responsive Styles */
@media (max-width: 1024px) {
    .banner {
        height: 400px; /* Adjust the height for tablet screens */
     }

     .slide {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 35%;
        left: 0;
    }
    .caption {
        font-size: 14px;
        max-height: 120px;
    }

    .prev, .next {
        width: 35px;
        height: 35px;
        font-size: 16px;
        top: 80%;
    }
}

@media (max-width: 768px) {
    .banner {
        height: 350px;
    }

    .caption {
        font-size: 13px;
        padding: 12px;
    }

    .prev, .next {
        width: 30px;
        height: 30px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .banner {
        height: 250px;
    }

    .caption {
        font-size: 12px;
        padding: 10px;
    }

    .prev, .next {
        width: 25px;
        height: 25px;
        font-size: 12px;
    }
}
  