.youtube-container {
   max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background:  #0b0c10
}

.youtube-title {
  font-size: 32px;
   text-align: center;
  margin-bottom: 10px;
  color: #66fcf1;
  text-shadow: 1px 0px 10px #66fcf1(0, 0, 0, 0.5); /* Shadow effect */
}

.samuel-title {
  color: #ffffff; /* Gold color */
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
 
}

.subscribe-btn-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.subscribe-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff0000;
  color: white;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.subscribe-btn:hover {
  background-color: #cc0000;
}

.youtube-icon {
  margin-right: 8px;
}

.featured-video {
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.videos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.video-item {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.toggle-btn {
  display: block;
  margin: 20px auto;
  background: linear-gradient(10deg, #1f2833, #66fcf1); /* Gradient background */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 4px;
  transition: background-color 0.3s;
  box-shadow: 1px 0px 15px #66fcf1; /* Shadow effect */

}

.toggle-btn:hover {
  background: #66fcf1;
    color: #1f2833;
     border-radius: 5px;
}

/* Media query for screens 720px and below */
@media (max-width: 720px) {
  .video-row {
    flex-direction: column;
  }

  .featured-video-wrapper {
    flex: none;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .small-videos-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .video-wrapper {
    flex: 1 1 48%;
    margin-right: 0;
  }

  .youtube-video-container {
    padding: 10px;
  }

  h1 {
    font-size: 28px;
  }

  .subscribe-button {
    font-size: 16px;
    padding: 8px 16px;
  }

  .show-more-btn {
    font-size: 16px;
    padding: 8px 16px;
  }
}

/* Media query for screens 480px and below */
@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }

  .video-row {
    flex-direction: column;
  }

  .featured-video-wrapper,
  .small-videos-wrapper {
    flex: none;
  }

  .video-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }

  .youtube-video-container {
    padding: 5px;
  }

  .subscribe-button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .show-more-btn {
    font-size: 14px;
    padding: 6px 12px;
  }
}