.contact-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1f2833;
    border-radius: 10px;
    box-shadow: 0 4px 8px #66fcf1(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .contact-form-container h2 {
    color: #66fcf1;
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .form-group label {
    margin-bottom: 5px;
    color: #ffffff;
    font-size: 16px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #ffffff;
  }
  
  .submit-btn {
    padding: 10px 20px;
    background-color: #1f2833;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 1px 0px 15px #66fcf1; /* Shadow effect */

  }
  
  .submit-btn:hover {
    background-color: #66fcf1;
    color: #1f2833;
    padding: 10px 20px;
    border-radius: 4px;
    transform: scale(1.0); /* Increase size on hover */
    

  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .contact-form-container {
     }
  
    .contact-form-container h2 {
      font-size: 28px;
    }
  
    .form-group label {
      font-size: 14px;
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 14px;
    }
  }
  