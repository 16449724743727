.whatsapp-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.whatsapp-link {
    display: flex;
    align-items: center;
    justify-content: center;
     
 }
