.contact-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #0b0c10;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: all 0.3s ease;

}

 
.contact-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.contact-container h2 {
  font-size: 32px;
  color: #66fcf1;
  margin-bottom: 20px;
  text-align: center;

}

.contact-infob {
  background-color: #1f2833;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px #66fcf1(0, 0, 0, 0.1);
}

.info-item {
  margin-bottom: 15px;
}

.info-item:last-child {
  margin-bottom: 0;
}

.info-item h3 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #66fcf1;
}

.info-item p {
  font-size: 16px;
  color: #ffffff;
}

.info-item a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.2s ease, text-decoration 0.2s ease;
}

.info-item a:hover {
  text-decoration: underline;
  color: #ffffff;
}
.phone-link {
  display: flex;
  align-items: center; /* Center align icon and text vertically */
  text-decoration: none;
  color: #003366; /* Set link color */
}

.phone-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* Circle width */
  height: 30px; /* Circle height */
  border-radius: 50%; /* Make it circular */
  background-color: #f4f4f4; /* Circle background color */
  margin-right: 8px; /* Space between icon and text */
  transition: background-color 0.2s ease;
}

.phone-icon:hover {
  background-color: #e0e0e0; /* Darker background on hover */
}

.phone-icon svg {
  color: #003366; /* Icon color */
  width: 16px; /* Icon width */
  height: 16px; /* Icon height */
}

/* Mobile view adjustments */
@media (max-width: 600px) {
  .contact-container {
     margin-top: 20%;

  }

  .contact-container h2 {
    font-size: 28px;
  }

  .info-item h3 {
    font-size: 18px;
  }

  .info-item p {
    font-size: 14px;
  }
}
