.faq-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #0b0c10;

    /* background: linear-gradient(135deg, rgb(253, 9, 106), #4a90e2); Linear gradient */
  }
  
  .faq-item {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: #000000;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s;
    box-shadow: 1px 0px 15px #66fcf1; /* Shadow effect */

  }
  
  .faq-question:hover {
    background: linear-gradient(90deg, #1f2833, #66fcf1); /* Gradient background */
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
  }
  
  .faq-answer.active {
    max-height: 500px; /* Set max height enough to fit the content */
    opacity: 1;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #1d2832;
    border-radius: 5px;
  }
  
  .faq-answer p {
    margin: 0;
    color: #333;
  }
  
   
    @media (max-width: 600px) {
    .faq-container {
      margin-top: 20%;

      padding: 10px;
    }
  
    .faq-question {
      font-size: 14px;
    }
  
    .faq-answer.active {
      max-height: 300px; /* Adjusted max height for mobile */
    }
  }
  