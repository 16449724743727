 /* Gallery container */
.gallery-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
 

}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.gallery-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05);
}

 /* Modal styles for desktop */
.modal {
  position: fixed; /* Change to fixed for full screen */
  top: 0; /* Align to top */
  left: 0; /* Align to left */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  padding: 0; /* No padding for full screen */
  outline: none;
  z-index: 2000;
}

.modal-image {
  width: 100%;
  height: 90%;
  object-fit: contain; /* Ensures the full image is shown without cropping */
  max-width: 100%; /* Adjusts for smaller screens */
  max-height: 100vh; /* Limits height to viewport on mobile */
}


.modal-content {
  position: relative; /* Allow positioning of arrows relative to modal */
  display: flex;
  align-items: center; /* Center the image vertically */
  height: 100%; /* Full height for content */
}

/* Arrow button styles */
.arrow-button {
  background: transparent;
  border: none;
  font-size: 3rem;
  color: #66fcf1; /* Change as needed */
  cursor: pointer;
  position: absolute; /* Position absolute for arrows */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust position */
  z-index: 2001; /* Ensure arrows are above other elements */
}

.arrow-button:first-child { /* Left arrow */
  left: 20px; /* Adjust as needed */
}

.arrow-button:last-child { /* Right arrow */
  right: 20px; /* Adjust as needed */
}

.close-buttonimg {
  position: absolute;
  top: 20px; /* Adjust position */
  right: 30px; /* Adjust position */
  background: transparent;
  border: none;
  font-size: 3rem;
  color: #66fcf1;
  cursor: pointer;
  z-index: 2500; /* Ensure close button is clickable and on top of everything */
}

.arrow-button:hover{
  background-color: #1f2833; 
}

.close-buttonimg:hover {
  color: #ff0000;
  background-color: #1f2833; /* Optional hover color change */
 }

 @media screen and (max-width: 720px) {

  .gallery-container{
    margin-top: 20%;

  }
  .modal-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
   }

  .modal-image {
    width: 100%;
    max-height: 70vh;
  }

  .arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.5rem;
    color: #66fcf1;
    cursor: pointer;
  }

  .arrow-button:first-child {
    left: 10px;
  }

  .arrow-button:last-child {
    right: 10px;
  }

  .close-buttonimg {
    top: 260px;
    color: #ff0000;

    right: 10px;
    font-size: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .arrow-button {
    font-size: 2rem;
  }

  .close-buttonimg {
    font-size: 1.5rem;
  }
}
