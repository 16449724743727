.footer {
    background: linear-gradient(10deg, #1f2833, #66fcf1); /* Gradient background */
    color: #F1F1F1; /* Text color */
    text-align: center;
    padding: 20px; /* Padding */
    box-shadow: 1px 0px 40px #66fcf1; /* Shadow effect */

}


.footer-content {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Spread items across the row */
    max-width: 1200px; /* Optional: Limit the width of the footer content */
    margin: 0 auto; /* Center footer content */
}

.footer_logo {
    flex: 1; /* Take equal space */
    display: flex;
    align-items: center; /* Center vertically */
}

.footer_logo img {
    max-width: 100px; /* Set a maximum width for the logo */
    height: auto; /* Maintain aspect ratio */
}

.contact-info {
    flex: 2; /* Take more space compared to logo */
    text-align: left; /* Align text to the left */
}

.social_media {
    display: flex;
    align-items: center;
    flex: 1; /* Take equal space */
}

.social_media a {
    margin-left: 20px; /* Adjust spacing between icons as needed */
}

.Contact-Uscolor {
    color: #010303;
    font-size: 30px;
}

.footer-bottom {
    margin-top: 20px; /* Spacing above the bottom section */
}

.divider {
    border: 0;
    height: 1px;
    background: #ffffff; /* White line */
    margin: 20px 0; /* Spacing around the line */
}

.copyright-notice {
    font-size: 14px; /* Font size */
    color: #F1F1F1; /* Text color */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        text-align: center; /* Center text */
    }

    .footer_logo,
    .contact-info,
    .social_media {
        flex: none; /* Reset flex property */
        margin-bottom: 10px; /* Add spacing below each item */
    }

    .social_media a {
        margin-left: 10px; /* Adjust spacing for mobile */
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 15px; /* Reduce padding on smaller screens */
    }

    .copyright-notice {
        font-size: 12px; /* Smaller font size for copyright notice */
    }
}
