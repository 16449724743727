.pdf-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.thumbnail-display {
  display: flex;
  align-items: center;
}

.pdf-thumbnail-container {
  text-align: center;
}

.pdf-thumbnail {
  width: 200px; /* Adjust as needed */
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.pdf-thumbnail:hover {
  transform: scale(1.05);
}

.pdf-caption {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #66fcf1;
  font-size: 20px;
}

.pdf-display {
  position: relative; /* Allows positioning the close and download buttons */
  width: 80%; /* Increase size for better visibility */
  height: auto;
  margin-top: 20px;
}

.close-button {
  position: absolute; /* Position it in the top right corner */
  top: 10px; /* Position from the top */
  right: 10px; /* Position from the right, use percentage or pixel as needed */
  background-color: #66fcf1;
  color: #1f2833;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10; /* Ensure it is above other elements */
}

.close-button:hover {
  background-color: #ff3c00;
}

.download-button {
  position: absolute; /* Position it in the top left corner */
  top: 10px; /* Position from the top */
  left: 10px; /* Position from the left, use percentage or pixel as needed */
  background-color: #66fcf1;
  color: #1f2833;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  z-index: 10; /* Ensure it is above other elements */
}



.download-button:hover {
  background-color: #ff3c00;
}

.nav-button {
  background-color: #66fcf1;
  color: #1f2833;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  box-shadow: 1px 0px 15px #66fcf1; /* Shadow effect */

}

.nav-button:hover {
  background-color: #1f2833;
  color: #66fcf1;
}

/* Responsive styles */
@media (max-width: 720px) {
  .pdf-thumbnail {
    width: 150px; /* Adjust size for smaller screens */
  }

  .pdf-display {
    width: 90%; /* Allow it to take up more space on smaller screens */
    padding: 10px;
  }

  .close-button,
  .download-button {
    padding: 8px; /* Adjust size for smaller screens */
    font-size: 14px; /* Make text smaller for mobile */
  }
}

@media (max-width: 480px) {
  .pdf-thumbnail {
    width: 100px; /* Further adjust for mobile screens */
  }

  .pdf-display {
    width: 100%; /* Full width on mobile */
    padding: 5px;
  }

  .close-button,
  .download-button {
    padding: 5px; /* Adjust size for smaller screens */
    font-size: 12px; /* Further reduce text size for mobile */
  }
}
