.pastors-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

 

.pastor-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 30px;
    text-align: justify;
    
}

 

.pastor-image {
    width: 100%;
    max-width: 230px;
    max-height: 250px;
    border-radius: 20px;
    margin-right: 20px; /* Space between image and text */
 }

.pastor-content {
    padding: 0 15px;
    flex: 1; /* Allow the content to take up available space */
    color: #66fcf1;

    
}

.pastor-content h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    font-size: 30px;
}

.pastor-content p {
    font-size: 1em;
    line-height: 1.6;
    color: white;
}

.show-more-btn {
    background: linear-gradient(10deg, #1f2833, #66fcf1); /* Gradient background */
    color: white; /* Text color */
    border: none; /* Remove border */
    padding: 10px 15px; /* Padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
    box-shadow: 1px 0px 15px #66fcf1; /* Shadow effect */

}


 

.show-more-btn:hover {
    background: #66fcf1;
    color: #ffffff;
    border: 2px solid #ffffff; /* Stroke changes color on hover */
    border-radius: 5px;
}

.show-more-btn:hover {
    background: #062434;
    color: #ffffff;
     border-radius: 5px;
 }

 

/* Mobile view adjustments */
@media (max-width: 768px) {
    .pastor-content h2 {
        font-size: 1.3em;
    }

    .pastor-content p {
        font-size: 0.9em;
        text-align: justify;
    }

    .pastor-card {
        flex-direction: column; /* Stack in mobile view */
        align-items: center; /* Center align */
    }

    .pastor-image {
        margin-right: 0; /* Remove right margin in mobile */
        margin-bottom: 15px; /* Add bottom margin in mobile */
    }
}

/* Additional mobile view adjustments for 480px */
@media (max-width: 480px) {
    .pastor-card {
        flex-direction: column; /* Stack images and text in mobile view */
        align-items: center; /* Center align */
    }

    .pastor-content {
        text-align: center; /* Center text for better readability */
    }
}
