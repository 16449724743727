body {
    margin: 0;
     background-color: #0b0c10 ; /* Background color with transparency */
     background-size: cover; /* Cover the entire viewport */
     background-repeat: no-repeat; /* Do not repeat the image */
}


.header {
    position: relative; /* If you want to position elements on top of the header */
    z-index: 1; /* Ensure header is above the background */
}

/* Additional styles for the header or any other elements can go here */



.no-scroll {
    overflow: hidden;
}

.nav-links {
    background-color:#1f2833 ;
    color: #66fcf1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000; /* Keeps the header on top of regular content but below the modal */
}

.logo-container {
    display: flex;
    color: #1f2833;
    background-color:#ffffff ;
    align-items: center;
    justify-content: space-between;
     width: 100%;
     height: 100px;
}

.left-section {
    display: flex;
    align-items: center;
}

.logo img {
    max-height: 100px;
    margin-right: 20px;
}

.college-info h1 {
    margin: 0;
    font-size: 40px;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav ul li {
    margin: 0 5px;
}

nav ul a {
    color: #66fcf1;
    text-decoration: none;
    padding: 10px;
    border-radius: 2px;
    transition: background-color 0.3s ease;
}

.HeaderP {
    color: #66fcf1;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.HeaderP:hover {
    background-color: #66fcf1;
    color: #1f2833;
    padding: 4px;
    border-radius: 4px;
    transform: scale(1.0); /* Increase size on hover */
    box-shadow: 1px 0px 15px #66fcf1; /* Shadow effect */

}

/* Responsive Design */
.menu-icon {
    display: none;
    cursor: pointer;
    color: #1f2833;
}

.nav-links.open {
    display: block;
}

 /* Media Queries for Responsive */
@media screen and (max-width: 720px) {
    /* Fix the logo and menu icon at the top */
    .logo-container {
        position: fixed; /* Fix at the top */
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #fff; /* Add background to ensure visibility */
        z-index: 1000; /* Ensure it stays above other content */
         height: 100px; /* Adjust height for mobile view */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
    }

    .menu-icon {
        display: block;
        z-index: 1000; /* Make sure it stays above the menu links */
    }

    .nav-links { 
        display: none;
        flex-direction: column;
        background-color: #1f2833;
        position: fixed;
        top: 100px; /* Open the menu right below the fixed logo section */
        right: 0;
        width: 100%;
        padding: 0;
        z-index: 900;
    }

    .nav-links.open {
        display: flex;
    }

    .HeaderP {
        padding: 2px;
        text-align: center;
        width: 100%;
    }

    nav ul {
        flex-direction: column;
        margin: 0;
    }

    nav ul li {
        margin: 0;
        padding: 5px 0;
        border-bottom: 1px solid #fff;
    }

    nav ul li:last-child {
        border-bottom: none;
    }
}

@media screen and (max-width: 480px) {
    .logo img {
        max-height: 80px;
    }

    .college-info h1 {
        font-size: 30px;
    }

    .nav-links {
        top: 80px; /* Adjust to fit below the smaller header */
        padding: 0;
    }

    nav ul li {
        padding: 5px 0;
    }
}
