 /* Container Styling */
.about-us-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #0b0c10;
}


/* Content Styling */
.about-us-content {
    max-width: 1000px;
    text-align: justify;
    padding: 20px;
}

.about-us-content h1,
.about-us-content h2 {
    margin: 10px;
    color: #66fcf1;
}

.about-us-content p {
    line-height: 1.6;
    margin: 10px 0;
     color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-us-container {
        flex-direction: column;
        padding: 10px;
        position: relative;
        margin-top: 20%;

    }

    .about-us-image {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .about-us-content {
        text-align: center;
        max-width: 100%;
        text-align: justify;

    }
}

@media (max-width: 480px) {
    .about-us-content h1 {
        font-size: 24px;
    }

    .about-us-content h2 {
        font-size: 20px;
    }

    .about-us-content p {
        font-size: 16px;
    }
}

 
